import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Tooltip } from 'wix-ui-tpa/cssVars';
import { useIsStepHidden } from '../../../../../contexts/IsStepHidden/IsStepHiddenContext';
import { useUser } from '../../../../../contexts/User/UserContext';
import utils, { isStepAvailableForComplete } from '../../views/utils';

import challengeSettings from '../../../settingsParams';
import { StepIcon } from '../../../../../components-shared/StepIcon';
import { useMonitoring } from '../../../../../contexts/Monitoring/MonitoringContext';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';
import LockedIcon from '../../../../../assets/icons/locked-fill.svg';
import CloseIcon from '../../../../../assets/icons/circle-close.svg';

import { FCWithChildren } from '@wix/challenges-web-library';
import { isUserCompleted } from '../../../../../contexts/User/helpers/userTypeHandlers';
import { getTitle } from '../../../../../selectors/step';
import { useVideoStepData } from '../../../../../hooks/useVideoStepData';

import { st, classes } from './StepsListAsTiles.st.css';
import { formatProgramVideoDuration } from '../VideoPlayerInStep/formatDuration';

export interface IStepSidebar {
  step: ParticipantStep;
  isSPC: boolean;
  currentStepId: string;
  onStepChosen: Function;
  className?: string;
}

export const StepSidebar: FCWithChildren<IStepSidebar> = (props) => {
  const { t } = useTranslation();
  const { isSPC, currentStepId, onStepChosen, step } = props;
  const { isStepHidden, isVisibleStepLockedForComplete } = useIsStepHidden();
  const { participant, participantV3 } = useUser();
  const settings = useSettings();
  const { startInteraction } = useMonitoring();
  const { isParticipantInSuspendedState, isParticipantInLockedState } =
    useUser();

  const isStepUnavailable =
    isStepHidden(step) || isVisibleStepLockedForComplete(step);
  const tooltip = t(
    utils.getStepTooltipKey(step, isStepUnavailable, isSPC, participant),
  );
  const tooltipDisabled =
    !isUserCompleted(participant?.transitions?.[0]?.state) &&
    isStepAvailableForComplete(step) &&
    !isStepUnavailable;
  const isCurrent = step?.id === currentStepId;
  const isQuizFailed = utils.isQuizFailed(step);

  const { stepType, videoStepDuration } = useVideoStepData(step?.source);

  let icon = (
    <StepIcon
      step={step}
      isUnavailable={isStepUnavailable}
      participant={participantV3 || participant}
    />
  );

  if (isQuizFailed) {
    icon = <CloseIcon />;
  } else if (isParticipantInSuspendedState || isParticipantInLockedState) {
    icon = <LockedIcon />;
  }

  return (
    <li
      className={st(classes.stepItem, {}, isCurrent && classes.stepItemActive)}
      data-id={step?.id}
      key={step?.id}
      onClick={() => {
        startInteraction?.(FedopsInteraction.ViewStepAtSidebarLayout, step?.id);
        onStepChosen(step);
      }}
    >
      <button
        className={st(classes.stepLink, {
          align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        })}
        onClick={() => {}}
      >
        <Tooltip
          className={classes.stepItemIcon}
          content={tooltip}
          placement="top"
          appendTo="window"
          maxWidth={250}
          // do not show tooltip for available step
          disabled={tooltipDisabled}
        >
          {icon}
        </Tooltip>
        <div className={classes.gapBetweenTitleAndIcon} />
        <TextWithDirection>
          <p className={classes.stepItemTitle}>{getTitle(step?.source)}</p>
        </TextWithDirection>
        {!!stepType && !isQuizFailed && videoStepDuration ? (
          <TextWithDirection>
            <p className={classes.stepItemSubtitle}>
              {/*t(`live-site.step-type-subtitle.${stepType.toLowerCase()}`) removed for now */}
              {videoStepDuration
                ? formatProgramVideoDuration(t, videoStepDuration)
                : ''}
            </p>
          </TextWithDirection>
        ) : null}
        {isQuizFailed ? (
          <TextWithDirection>
            <p className={classes.stepItemSubtitle}>
              {t('live-site.step-quiz.step-failed-subtitle')}
            </p>
          </TextWithDirection>
        ) : null}
      </button>
    </li>
  );
};

StepSidebar.displayName = 'StepSidebarItem';
